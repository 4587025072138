import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout({
    children,
    asideDisplay,
    subheaderDisplay,
    selfLayout,
    layoutConfig,
    contentContainerClasses
}) {
    htmlClassService.setConfig(layoutConfig);

    const contentCssClasses = htmlClassService.classes.content.join(" ");

    if (selfLayout === "blank") {
        return (
            // BLANK LAYOUT
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <KtContent>
                    {children}
                </KtContent>
            </div>
        );
    }

    return (
        <LayoutInitializer menuConfig={MenuConfig} layoutConfig={LayoutConfig} htmlClassService={htmlClassService}>
            <HeaderMobile />

            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    {asideDisplay && (<AsideLeft />)}
                    <div id="kt_wrapper" className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <Header />

                        <div id="kt_content" className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}>
                            <KtContent>
                                {children}
                            </KtContent>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>

        </LayoutInitializer>
    );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
        layoutConfig,
        "header.self.fixed.desktop"
    ),
    contentContainerClasses: ""
});

export default connect(mapStateToProps)(Layout);
