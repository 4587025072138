export default {
    header: {
        self: {},
        items: [

            {
                title: "Dashboard",
                root: true,
                alignment: "left",
                page: "dashboard",
                translate: "MENU.DASHBOARD"
            },
        ]
    },
    aside: {
        self: {},
        items: [
            {
                title: "Ventas",
                root: true,
                bullet: "dot",
                translate: "MENU.VENTAS",
                icon: "flaticon-piggy-bank",
                permiso: "ventas",
                submenu: [{
                    title: "Carritos de compras",
                    root: true,
                    icon: "flaticon-cart",
                    page: "carritos",
                    translate: "MENU.CARRITOS",
                    bullet: "dot",
                    permiso: "ventas.carritos",
                },
                {
                    title: "Pedidos",
                    root: true,
                    icon: "flaticon2-delivery-truck",
                    page: "pedidos",
                    translate: "MENU.PEDIDOS",
                    bullet: "dot",
                    permiso: "ventas.pedidos",
                },
                {
                    title: "Cupones",
                    root: true,
                    icon: "flaticon-web",
                    page: "cupones",
                    translate: "MENU.CUPONES",
                    bullet: "dot",
                    permiso: "ventas.cupones",
                },
                ]
            },
            {
                title: "Catalogo",
                root: true,
                bullet: "dot",
                translate: "MENU.CATALOGO",
                icon: "flaticon-list-2",
                permiso: "catalogo",
                submenu: [
                    {
                        title: "Marcas",
                        root: true,
                        icon: "flaticon2-tag",
                        page: "marcas",
                        translate: "MENU.MARCAS",
                        bullet: "dot",
                        permiso: "catalogo.marcas",
                    },
                    {
                        title: "Atributos",
                        root: true,
                        icon: "flaticon-signs-1",
                        page: "atributos",
                        translate: "MENU.ATRIBUTOS",
                        bullet: "dot",
                        permiso: "catalogo.atributos",
                    },
                    {
                        title: "Categorías",
                        root: true,
                        icon: "flaticon-folder-1",
                        page: "categorias",
                        translate: "MENU.CATEGORIAS",
                        bullet: "dot",
                        permiso: "catalogo.categorias",
                    },
                    {
                        title: "Productos",
                        root: true,
                        icon: "flaticon2-open-box",
                        page: "productos",
                        translate: "MENU.PRODUCTOS",
                        bullet: "dot",
                        permiso: "catalogo.productos",
                    },
                    {
                        title: "Promociones",
                        root: true,
                        icon: "flaticon-coins",
                        page: "promociones",
                        translate: "MENU.PROMOCIONES",
                        bullet: "dot",
                        permiso: "catalogo.promociones",
                    },
                    {
                        title: "Etiquetas",
                        root: true,
                        icon: "flaticon-price-tag",
                        page: "etiquetas",
                        translate: "MENU.ETIQUETAS",
                        bullet: "dot",
                        permiso: "catalogo.etiquetas",
                    },
                    {
                        title: "Listas de precios",
                        root: true,
                        icon: "flaticon2-tag",
                        page: "listasprecios",
                        translate: "MENU.LISTASPRECIOS",
                        bullet: "dot",
                        permiso: "catalogo.listasprecios",
                    },
                ]
            },
            {
                title: "Búsquedas en la tienda",
                root: true,
                page: "busquedas",
                translate: "MENU.BUSQUEDAS",
                bullet: "dot",
                icon: "flaticon-profile-1",
                permiso: "busquedas",
            },
            {
                title: "Clientes",
                root: true,
                page: "clientes",
                translate: "MENU.CLIENTES",
                bullet: "dot",
                icon: "flaticon-profile-1",
                permiso: "clientes",
            },
            {
                title: "Contenido",
                root: true,
                bullet: "dot",
                translate: "MENU.CONTENIDO",
                icon: "flaticon-folder-1",
                permiso: "contenido",
                submenu: [
                    {
                        title: "Landings",
                        root: true,
                        icon: "flaticon2-open-box",
                        page: "landings",
                        translate: "MENU.LANDINGS",
                        bullet: "dot",
                        permiso: "contenido.landings",
                    },
                    {
                        title: "Plantillas",
                        root: true,
                        icon: "flaticon2-open-box",
                        page: "templates",
                        translate: "MENU.TEMPLATES",
                        bullet: "dot",
                        permiso: "contenido.templates",
                    },
                    {
                        title: "Plantillas de emails",
                        root: true,
                        icon: "flaticon2-open-box",
                        page: "templates-emails",
                        translate: "MENU.TEMPLATES_EMAILS",
                        bullet: "dot",
                        permiso: "contenido.emailtemplates",
                    }
                ]
            },
            {
                title: "Configuración",
                root: true,
                bullet: "dot",
                icon: "flaticon-cogwheel-1",
                translate: "MENU.CONFIGURACION",
                permiso: "configuracion",
                submenu: [
                    {
                        title: "Países",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "paises",
                        translate: "MENU.PAISES",
                        bullet: "dot",
                        permiso: "configuracion.paises",
                    },
                    {
                        title: "Provincias",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "provincias",
                        translate: "MENU.PROVINCIAS",
                        bullet: "dot",
                        permiso: "configuracion.provincias",
                    },
                    {
                        title: "Localidades",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "localidades",
                        translate: "MENU.LOCALIDADES",
                        bullet: "dot",
                        permiso: "configuracion.localidades",
                    },
                    {
                        title: "Códigos postales",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "codigospostales",
                        translate: "MENU.CODIGOSPOSTALES",
                        bullet: "dot",
                        permiso: "configuracion.codigospostales",
                    },
                    {
                        title: "Zonas",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "zonas",
                        translate: "MENU.ZONAS",
                        bullet: "dot",
                        permiso: "configuracion.zonas",
                    },
                    {
                        title: "Sucursales",
                        root: true,
                        icon: "flaticon-map-location",
                        page: "sucursales",
                        translate: "MENU.SUCURSALES",
                        bullet: "dot",
                        permiso: "configuracion.sucursales",
                    },
                    {
                        title: "Puntos de retiro",
                        root: true,
                        icon: "flaticon-map-location",
                        page: "puntosretiro",
                        translate: "MENU.RETIROS",
                        bullet: "dot",
                        permiso: "configuracion.puntosretiro",
                    },
                    {
                        title: "Envíos",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "envios",
                        translate: "MENU.ENVIOS",
                        bullet: "dot",
                        permiso: "configuracion.envios",
                    },
                    {
                        title: "Transportistas",
                        root: true,
                        icon: "flaticon2-delivery-truck",
                        page: "transportistas",
                        translate: "MENU.TRANSPORTISTAS",
                        bullet: "dot",
                        permiso: "configuracion.transportistas",
                    },
                    {
                        title: "Bancos",
                        root: true,
                        icon: "flaticon2-chart",
                        page: "bancos",
                        translate: "MENU.BANCOS",
                        bullet: "dot",
                        permiso: "configuracion.bancos",
                    },
                    {
                        title: "Tarjetas",
                        root: true,
                        icon: "flaticon-shopping-basket",
                        page: "tarjetas",
                        translate: "MENU.TARJETAS",
                        bullet: "dot",
                        permiso: "configuracion.tarjetas",
                    },
                    {
                        title: "Códigos de comercio",
                        root: true,
                        icon: "flaticon2-shopping-cart-1",
                        page: "codigosdecomercio",
                        translate: "MENU.CODIGOSDECOMERCIO",
                        bullet: "dot",
                        permiso: "configuracion.codigosdecomercio",
                    },
                    {
                        title: "Medios de pagos",
                        root: true,
                        icon: "flaticon2-shopping-cart-1",
                        page: "mediosdepagos",
                        translate: "MENU.MEDIOSDEPAGOS",
                        bullet: "dot",
                        permiso: "configuracion.mediosdepago",
                    },
                    {
                        title: "Grupos de usuarios",
                        root: true,
                        icon: "flaticon-users",
                        page: "gruposdeusuarios",
                        translate: "MENU.GRUPOSDEUSUARIOS",
                        bullet: "dot",
                        permiso: "configuracion.gruposdeusuarios",
                    },
                    {
                        title: "Usuarios",
                        root: true,
                        icon: "flaticon-user",
                        page: "usuarios",
                        translate: "MENU.USUARIOS",
                        bullet: "dot",
                        permiso: "configuracion.usuarios",
                    },
                    {
                        title: "Divisas",
                        root: true,
                        icon: "flaticon-coins",
                        page: "divisas",
                        translate: "MENU.DIVISAS",
                        bullet: "dot",
                        permiso: "configuracion.divisas",
                    },
                    {
                        title: "Tasas de cambio",
                        root: true,
                        icon: "flaticon-coins",
                        page: "tasasdecambio",
                        translate: "MENU.TASASDECAMBIO",
                        bullet: "dot",
                        permiso: "configuracion.tasasdecambio",
                    },
                    {
                        title: "Estados",
                        root: true,
                        icon: "flaticon2-list",
                        page: "estados",
                        translate: "MENU.ESTADOS",
                        bullet: "dot",
                        permiso: "configuracion.estados",
                    },
                    {
                        title: "Log interfaz precios ERP",
                        root: true,
                        icon: "flaticon-placeholder-1",
                        page: "logpreciosavenew",
                        translate: "MENU.LOG_PRECIOSAVENEW",
                        bullet: "dot",
                        permiso: "configuracion.log_preciosavenew",
                    },
                ]
            },
            {
                title: "Configuración tienda",
                root: true,
                icon: "flaticon-web",
                page: "configuraciones_tiendas",
                translate: "MENU.CONFIGURACIONESTIENDAS",
                bullet: "dot",
                permiso: "configuraciontienda",
            },
            {
                title: "Configuración soporte",
                root: true,
                icon: "flaticon-web",
                page: "configuraciones_soporte",
                translate: "MENU.CONFIGURACIONESSOPORTE",
                bullet: "dot",
                permiso: "configuracionessoporte",
            },
            {
                title: "Configuraciones extra",
                root: true,
                icon: "flaticon-web",
                page: "configuraciones",
                translate: "MENU.CONFIGURACIONES",
                bullet: "dot",
                permiso: "configuracionesextra",
            },
            {
                title: "Plugins",
                root: true,
                bullet: "dot",
                translate: "MENU.PLUGINS",
                icon: "flaticon-apps",
                permiso: "plugins",
                submenu: [
                    {
                        title: "Botón de Mercadopago",
                        root: true,
                        icon: "flaticon-app",
                        page: "plugins/mercadopagobtn",
                        bullet: "dot",
                        permiso: "plugins.mercadopagobtn",
                    },
                    {
                        title: "MercadoPago Gateway",
                        root: true,
                        icon: "flaticon-app",
                        page: "plugins/mercadopagogtw",
                        bullet: "dot",
                        permiso: "plugins.mercadopagogtw",
                    },
                    {
                        title: "Logs de MercadoPago Gateway",
                        root: true,
                        icon: "flaticon-app",
                        page: "plugins/mercadopagogtwlogs",
                        bullet: "dot",
                        permiso: "plugins.mercadopagogtw",
                    },
                ]
            },
            {
                title: "Notificaciones",
                root: true,
                bullet: "dot",
                translate: "MENU.NOTIFICACIONES",
                icon: "flaticon2-notification",
                page: "notificaciones",
            },
            {
                title: "Crear notificacion general",
                root: true,
                bullet: "dot",
                translate: "MENU.NOTIFICACIONGRAL",
                icon: "flaticon-alert-2",
                page: "notificacion-general",
                permiso: "configuracionessoporte"
            },
            {
                title: "Galeria de imagenes",
                root: true,
                bullet: "dot",
                translate: "MENU.GALERIA",
                icon: "flaticon-folder-1",
                page: 'galeria'
            },
            {
                title: "Cotizaciones (proximamente)",
                root: true,
                bullet: "dot",
                translate: "MENU.COTIZACIONES",
                icon: "flaticon-piggy-bank",
                page: 'dashboard'
            },
            {
                title: "Vendedores (proximamente)",
                root: true,
                bullet: "dot",
                translate: "MENU.COTIZACIONES",
                icon: "flaticon-users-1",
                page: 'dashboard'
            },
        ]
    }
};
