import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";

import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import NotificacionGeneral from "./notificaciongeneral/NotificacionGeneralFormPage";

const MarcasPage = lazy(() =>
  import("./marcas/MarcasPage")
);

const ImagenesForm = lazy(() => import("./imagenes/ImagenesFormPage"));

const CodigosdecomercioPage = lazy(() =>
  import("./codigosdecomercio/CodigosdecomercioPage")
);

const ListasPreciosPage = lazy(() =>
  import("./listasprecios/ListasPreciosPage")
);

const TasasDeCambioPage = lazy(() =>
  import("./tasasdecambio/TasasDeCambioPage")
);

const BancosPage = lazy(() =>
  import("./bancos/BancosPage")
);

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);

const MediosDePagosPage = lazy(() =>
  import("./mediosdepagos/MediosDePagosPage")
);

const UsuariosPage = lazy(() =>
  import("./usuarios/UsuariosPage")
);

const ProvinciasPage = lazy(() =>
  import("./provincias/ProvinciasPage")
);

const SucursalesPage = lazy(() =>
  import("./sucursales/SucursalesPage")
);

const TarjetasPage = lazy(() =>
  import("./tarjetas/TarjetasPage")
);

const DivisasPage = lazy(() =>
  import("./divisas/DivisasPage")
);

const ProductosPage = lazy(() =>
  import("./productos/ProductosPage")
);

const EstadosPage = lazy(() =>
  import("./estados/EstadosPage")
);

const CuponesPage = lazy(() =>
  import("./cupones/CuponesPage")
);

const AtributosPage = lazy(() =>
  import("./atributos/AtributosPage")
);

const CategoriasPage = lazy(() =>
  import("./categorias/CategoriasPage")
);

const ClientesPage = lazy(() =>
  import("./clientes/ClientesPage")
);

const GruposdeUsuariosPage = lazy(() =>
  import("./gruposdeusuarios/GruposdeUsuariosPage")
);

const LocalidadesPage = lazy(() =>
  import("./localidades/LocalidadesPage")
);

const ConfiguracionesPage = lazy(() =>
  import("./configuraciones/ConfiguracionesPage")
);

const PedidosPage = lazy(() =>
  import("./pedidos/PedidosPage")
);

const PaisesPage = lazy(() =>
  import("./paises/PaisesPage")
);

const PromocionesPage = lazy(() =>
  import("./promociones/PromocionesPage")
);

const CarritosPage = lazy(() =>
  import("./carritos/CarritosPage")
);

const CodigosPostalesPage = lazy(() =>
  import("./codigospostales/CodigosPostalesPage")
);

const ZonasPage = lazy(() =>
  import("./zonas/ZonasPage")
);

const EnviosPage = lazy(() =>
  import("./envios/EnviosPage")
);

const TransportistasPage = lazy(() => 
  import("./transportistas/TransportistasPage")
);

const PuntosRetiroPage = lazy(() =>
  import("./puntosretiro/PuntosRetiroPage")
);
const ConfiguracionesTiendasPage = lazy(() =>
  import("./configuraciones_tiendas/ConfiguracionesTiendasPage")
);

const BusquedasPage = lazy(() =>
  import("./busquedas/BusquedasPage")
);

const EtiquetasPage = lazy(() =>
  import("./etiquetas/EtiquetasPage")
);

const TemplatesPage = lazy(
  () => import("./templates/TemplatesPage")
);

const TemplatesEmailsPage = lazy(
  () => import("./templates-emails/TemplatesEmailsPage")
);

const LandingsPage = lazy(
  () => import("./landings/LandingsPage")
);

const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const Plugins_Mercadopagobtn = lazy(() =>
  import("./plugins/mercadopagobtn/MercadopagoBtnFormPage")
);

const Plugins_Mercadopagogtw = lazy(() =>
    import("./plugins/mercadopagogtw/MercadopagoGtwFormPage")
);

const Plugins_Mercadopagogtw_logs = lazy(() =>
    import("./plugins/mercadopagogtw/MercadoPagoGtwLogsPage")
);

const Notificaciones = lazy(() =>
    import("./notificaciones/NotificacionesPage")
);

const LogPreciosAvenewPage = lazy(() =>
  import("./log_preciosavenew/LogPreciosAvenewPage")
);

const ConfiguracionesSoportePage = lazy(() =>
  import("./configuraciones_soporte/ConfiguracionesSoportePage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/productos" component={ProductosPage} />
        <Route path="/tarjetas" component={TarjetasPage} />
        <Route path="/mediosdepagos" component={MediosDePagosPage} />
        <Route path="/divisas" component={DivisasPage} />
        <Route path="/provincias" component={ProvinciasPage} />
        <Route path="/usuarios" component={UsuariosPage} />
        <Route path="/sucursales" component={SucursalesPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/marcas" component={MarcasPage} />
        <Route path="/estados" component={EstadosPage} />
        <Route path="/cupones" component={CuponesPage} />
        <Route path="/atributos" component={AtributosPage} />
        <Route path="/categorias" component={CategoriasPage} />
        <Route path="/clientes" component={ClientesPage} />
        <Route path="/codigosdecomercio" component={CodigosdecomercioPage} />
        <Route path="/gruposdeusuarios" component={GruposdeUsuariosPage} />
        <Route path="/localidades" component={LocalidadesPage} />
        <Route path="/tasasdecambio" component={TasasDeCambioPage} />
        <Route path="/bancos" component={BancosPage} />
        <Route path="/pedidos" component={PedidosPage} />
        <Route path="/paises" component={PaisesPage} />
        <Route path="/promociones" component={PromocionesPage} />
        <Route path="/configuraciones" component={ConfiguracionesPage} />
        <Route path="/carritos" component={CarritosPage} />
        <Route path="/codigospostales" component={CodigosPostalesPage} />
        <Route path="/zonas" component={ZonasPage} />
        <Route path="/envios" component={EnviosPage} />
        <Route path="/transportistas" component={TransportistasPage} />
        <Route path="/puntosretiro" component={PuntosRetiroPage} />
        <Route path="/landings" component={LandingsPage} />
        <Route path="/templates" component={TemplatesPage} />
        <Route path="/templates-emails" component={TemplatesEmailsPage} />
        <Route path="/configuraciones_tiendas" component={ConfiguracionesTiendasPage} />
        <Route path="/busquedas" component={BusquedasPage} />
        <Route path="/etiquetas" component={EtiquetasPage} />
        <Route path="/plugins/mercadopagobtn" component={Plugins_Mercadopagobtn} />
        <Route path="/plugins/mercadopagogtw" component={Plugins_Mercadopagogtw} />
        <Route path="/plugins/mercadopagogtwlogs" component={Plugins_Mercadopagogtw_logs} />
        <Route path="/notificaciones" component={Notificaciones} />
        <Route path="/notificacion-general" component={NotificacionGeneral} />
        <Route path="/listasprecios" component={ListasPreciosPage} />
        <Route path="/logpreciosavenew" component={LogPreciosAvenewPage} />
        <Route path="/configuraciones_soporte" component={ConfiguracionesSoportePage} />
        <Route path="/galeria" component={ImagenesForm} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
