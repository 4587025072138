import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RequestHelper from 'request-helper/dist/request';
import Tooltip from '@material-ui/core/Tooltip';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "./Portlet";
import Grid from '@material-ui/core/Grid';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import Toast from './Toast';
import { LocalStorageHelper } from "../../helpers/LocalStorageHelper";
import SinPermiso from './SinPermiso';
import ProgresoCircular from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

export class EdicionGenerica extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: this.useStyles,
            mensajesErrores: [],
            camposErrores: [],
            tipoMutacion: this.props.tipoMutacion ? this.props.tipoMutacion : `guardar`,
            mostrarToast: false,
            mostrarSinPermiso: false,
            bloquearEdicion: false,
            entidadCargada: false,
            nuevoRegistro: false,
            mostrarLoading:  this.props.mostrarLoading == false ? false : true,
        };

        this.cerrarToast = this.cerrarToast.bind(this);
        this.cancelar = this.cancelar.bind(this);
        this.guardar = this.guardar.bind(this);
    }

    useStyles = makeStyles(theme => ({
        paper: {
            width: '100%',
            padding: theme.spacing(2),
        },
        errorIcon: {
            marginRight: theme.spacing(1),
        }
    }));

    async componentDidMount() {
        const id = parseInt(this.props.idEntidad);
        const permisos = LocalStorageHelper.leer("permisos");

        const tienePermisoCrear = !this.props.soloVision && (!this.props.permisoCrear || permisos.find(x => x.nombre === this.props.permisoCrear) !== undefined);
        const tienePermisoEditar = !this.props.soloVision && (!this.props.permisoEditar || permisos.find(x => x.nombre === this.props.permisoEditar) !== undefined);
        const tienePermisoVer = !this.props.permisoVer || permisos.find(x => x.nombre === this.props.permisoVer) !== undefined;

        if (!id && !this.props.sinId && !tienePermisoCrear) {
            this.setState({ mostrarSinPermiso: true });
            return;
        }
        if ((id || this.props.sinId) && !tienePermisoEditar) {
            if (tienePermisoVer) {
                this.setState({ bloquearEdicion: true });
            } else {
                this.setState({ mostrarSinPermiso: true });
                return;
            }
        }

        if (this.props.sinId) {
            await this.inicializarEdicion(null);
            return;
        }

        if (!id || isNaN(id))
            await this.inicializarNuevo();
        else
            await this.inicializarEdicion(id);
    }
    async inicializarNuevo() {
        this.setState({entidadCargada: true, nuevoRegistro: true})
    }
    async inicializarEdicion(id) {
        if (!this.props.microservicio)
            return;
        if(!this.props.noInicializar){
            const listaCampos = this.listaCamposMicroservicio(this.props.campos);
            let query;
            if (this.props.sinId) {
                query = {
                    "query": `query { ${this.props.nombreEntidad} { resultado errores { codigo mensaje } item {${listaCampos} ${this.props?.camposGetExtra || ''}} } }`,
                };
            } else {
                const variables = {
                    "id": id,
                };
                query = {
                    "query": `query getEntidad($id: ID!) {${this.props.nombreEntidad}(id: $id) {resultado errores {mensaje codigo} item {${listaCampos}  ${this.props?.camposGetExtra || ''}} } }`,
                    "variables": variables
                };
            }
            const data = await RequestHelper.consultaMicroservicio(this.props.microservicio, query, window.key, LocalStorageHelper.leer("token"));
            const resultado = data.body.data[this.props.nombreEntidad];
            //TODO Validar resultado de retorno
            if (this.props.actualizarEntidad)
                await this.props.actualizarEntidad(resultado.item);
        }
        this.setState({entidadCargada: true})
    }
    listaCamposMicroservicio(campos) {
        let listaCampos = "";
        for (let campo of campos) {
            if (campo.noLeer) continue;
            listaCampos += campo.nombre + " ";
            if (campo.campos) {
                listaCampos += "{";
                if (Array.isArray(campo.campos)) {
                    listaCampos += this.listaCamposMicroservicio(campo.campos);
                } else {
                    listaCampos += campo.campos;
                }
                listaCampos += "} ";
            }
        }
        return listaCampos;
    }

    cancelar() {
        if (this.props.urlListado)
            this.props.history.push(this.props.urlListado);
        if (this.props.cerrar)
            this.props.cerrar();
    }
    async guardar(volver) {
        if (!this.props.microservicio) {
            return;
        }
        let id_usuarioCreacion, resultado, data;
        if(this.props.guardarEspecifico){
            data = await this.props.guardarEspecifico();
            resultado = data.body.data[this.state.tipoMutacion];
        } else {
            if (this.props.noGuardarUsuario) {
                id_usuarioCreacion = null;
            } else {
                id_usuarioCreacion = LocalStorageHelper.leer("usuario").id;
                if (isNaN(this.props.entidad.id) || this.props.entidad.id == null || this.props.entidad.id == undefined) {
                    if (this.props.campos.find(elemento => elemento.nombre == "id_usuarioCreacion") == undefined) {
                        this.props.campos.push({ nombre: "id_usuarioCreacion", tipo: "ID" })
                    }
                    this.props.entidad.id_usuarioCreacion = id_usuarioCreacion;
                }
            }
            const variables = this.variablesGuardar(this.props.campos, this.props.entidad, id_usuarioCreacion);
            const listaCampos = this.props.campos.filter(x => x.noGuardar !== true).map(x => x.nombre + ": $" + x.nombre).join(", ");
            const listaParametros = this.props.campos.filter(x => x.noGuardar !== true).map(x => "$" + x.nombre + ": " + x.tipo).join(", ");
            variables.datosABM = {
                id_usuarioABM: LocalStorageHelper.leer("usuario").id,
            };
            const query = {
                "query": `mutation LlamarGuardar(${listaParametros}, $datosABM: DatosABM) {${this.state.tipoMutacion}(${this.props.nombreEntidad}: {${listaCampos}}, datosABM: $datosABM) {resultado errores {mensaje codigo campos} ${this.props.propiedadesItem ? this.props.propiedadesItem : this.props.sinId ? '' : 'item {id}'} } }`,
                "variables": variables
            };
            data = await RequestHelper.consultaMicroservicio(this.props.microservicio, query, window.key, LocalStorageHelper.leer("token"));
            resultado = data.body.data[this.state.tipoMutacion];
        }
        if (resultado.resultado) {
            if (volver){
                this.setState({mostrarToast: true})
                this.cancelar();
            }
            else {
                if (this.props.urlEdicion && (this.props.sinId == false || this.props.sinId == undefined))
                    this.props.history.push(this.props.urlEdicion(resultado.item.id));
                const entidadGuardada = this.props.entidad;
                if (this.props.actualizarPropiedadesItem){
                    this.props.actualizarPropiedadesItem(entidadGuardada, resultado.item)
                }
                else if (!this.props.sinId) entidadGuardada.id = resultado.item.id;
                this.setState({
                    mensajesErrores: [],
                    camposErrores: [],
                });
                if (this.props.actualizarEntidad)
                    this.props.actualizarEntidad(entidadGuardada);
                if (this.props.actualizarCamposErrores)
                    this.props.actualizarCamposErrores([]);
                this.setState({
                    mostrarToast: true
                });
            }

            return;
        }

        let nuevoCamposErrores = [];
        let nuevoMensajesErrores = [];

        for (let error of resultado.errores) {
            nuevoMensajesErrores.push(error.mensaje);
            if (error.campos) {
                for (let campo of error.campos) {
                    nuevoCamposErrores.push(campo);
                }
            }
        }

        this.setState({
            mensajesErrores: nuevoMensajesErrores,
            camposErrores: nuevoCamposErrores,
        });
        if (this.props.actualizarCamposErrores)
            this.props.actualizarCamposErrores(nuevoCamposErrores);

        if (nuevoCamposErrores.length > 0) {
            this.setState({
                mostrarToast: false
            })
        }
    }

    variablesGuardar(campos, entidad, id_usuarioCreacion) {
        let variables = {};
        for (let i = 0; i < campos.length; i++) {
            if (campos[i].noGuardar === true) continue;

            if (campos[i].muchosAMuchos) {
                variables[campos[i].nombre] = entidad[campos[i].nombre].map(x => parseInt(x.id));
            }
            else if (campos[i].unoAMuchos) {
                let listaCopiada = [];
                for (let item of entidad[campos[i].nombre]) {
                    if (Array.isArray(campos[i].campos)) {
                        if (isNaN(item.id) || item.id == undefined) {
                            if(!campos[i].noGuardarUsuarioCreacion){
                                item.id_usuarioCreacion = id_usuarioCreacion;
                                campos[i].campos.push({ nombre: "id_usuarioCreacion", tipo: "ID" })
                            }
                        }
                        const nuevoItem = this.variablesGuardar(campos[i].campos, item);
                        listaCopiada.push(nuevoItem);
                    } else {
                        let nuevoItem = {};
                        Object.assign(nuevoItem, item);
                        if (nuevoItem.tableData) delete nuevoItem.tableData;
                        listaCopiada.push(nuevoItem);
                    }
                }
                variables[campos[i].nombre] = listaCopiada;
            } else if (campos[i].tipo === "Int" || campos[i].tipo === "ID") {
                variables[campos[i].nombre] = parseInt(entidad[campos[i].nombre]);
            } else if (campos[i].tipo === "Float") {
                variables[campos[i].nombre] = parseFloat(entidad[campos[i].nombre]);
            } else if (campos[i].tipo === "Boolean") {
                variables[campos[i].nombre] = entidad[campos[i].nombre] === true || entidad[campos[i].nombre] === 1;
            } else {
                variables[campos[i].nombre] = entidad[campos[i].nombre];
            }
        }
        return variables;
    }

    botonesHeader() {
        return (
            <div style={{maxWidth: '270px'}}>
            {
                this.state.entidadCargada == true || this.props.noInicializar ?
                <PortletHeaderToolbar style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: '-5px'}}>
                {this.props.noInicializar || (this.props.microservicio && !this.props.soloVision && !this.state.bloquearEdicion) ? <Tooltip title="Guardar">
                    <button type="button" onClick={() => this.guardar(true)} className="btn btn-clean btn-md btn-icon btn-icon-md ng-star-inserted">
                        <i className="fa fa-save" style={{"fontSize": "18px", "color": "#687cde"}}/> <span>Guardar y Salir</span>
                    </button>
                </Tooltip> : null}
                { this.props.noInicializar || (this.props.microservicio && !this.props.soloVision && !this.state.bloquearEdicion) ? <Tooltip title="Aplicar">
                    <button type="button" onClick={() => this.guardar(false)} className="btn btn-clean btn-md btn-icon btn-icon-md ng-star-inserted">
                        <i className="fa flaticon-download" style={{"fontSize": "18px", "color": "#687cde"}}/> <span>Aplicar cambios</span>
                    </button>
                </Tooltip> : null}
                <Tooltip title={this.props.microservicio && !this.props.soloVision && !this.state.bloquearEdicion ? "Cancelar" : "Cerrar"}>
                    <button type="button" onClick={this.cancelar} className="btn btn-clean btn-md btn-icon btn-icon-md ng-star-inserted">
                        <i className="fas fa-times-circle" style={{"fontSize": "18px", "color": "#687cde"}}></i> <span>Cancelar</span>
                    </button>
                </Tooltip>
                {this.state.mostrarToast && <Toast cerrarToast={this.cerrarToast} />}
            </PortletHeaderToolbar>: <div> </ div>
            }

            </div>

        );
    }

    cerrarToast() {
        this.setState({
            mostrarToast: false
        })
    }

    mensajesErrores() {
        if (this.state.mensajesErrores.length === 0) return (null);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SnackbarContent message={
                        <span>
                            <Icon className={"flaticon-warning-sign " + this.state.classes.errorIcon} />
                            <ul>
                                {this.state.mensajesErrores.map(x => (<li key={x}>{x}</li>))}
                            </ul>
                        </span>
                    } />
                </Grid>
            </Grid>
        );
    }

    render() {
        if (this.state.mostrarSinPermiso) return (<SinPermiso />);

        return (
            <div>
                <Paper className={this.state.classes.paper}>
                    <Portlet>
                        <PortletHeader title={(this.props.soloVision || this.state.bloquearEdicion ? 'Ver' : (this.state.nuevoRegistro == true ? 'Crear' : 'Editar')) + ' ' + this.props.titulo} toolbar={this.botonesHeader()} />
                        <PortletBody>
                        {
                            this.state.entidadCargada == false && this.state.mostrarLoading == true ?
                            <div style={{textAlign: "center"}}>
                                <ProgresoCircular color="primary" ></ProgresoCircular>
                            </div>
                            :
                            <Fade in={true} timeout={750}>
                                <div>
                                    {this.mensajesErrores()}
                                    {this.props.children}
                                </div>
                            </Fade>
                        }
                        </PortletBody>
                    </Portlet>
                </Paper>
            </div>
        );
    }
}

export default withRouter(EdicionGenerica);
