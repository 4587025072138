import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { LocalStorageHelper } from "../../helpers/LocalStorageHelper";
import LanguageSelector from "./LanguageSelector";

export default class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = { usuario: LocalStorageHelper.leer("usuario") };
    }

    render() {
        return (
            <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
                <Dropdown.Toggle
                    as={HeaderDropdownToggle}
                    id="dropdown-toggle-user-profile"
                >
                    <div className="kt-header__topbar-user">
                        <span className="kt-header__topbar-username">
                            {this.state.usuario.nombre}&nbsp;
                            <span className="kt-hidden-mobile">
                                {this.state.usuario.apellido}
                            </span>
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                    <div
                        className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
                        }}
                    >
                        <div className="kt-user-card__avatar">
                            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                                S
                            </span>
                        </div>
                        <div className="kt-user-card__name">{this.state.usuario.nombre} {this.state.usuario.apellido}</div>
                        <div className="kt-user-card__badge">
                        </div>
                    </div>
                    <div className="kt-notification">
                        {/**
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-calendar-3 kt-font-success" />
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Profile
                                </div>
                                <div className="kt-notification__item-time">
                                    Account settings and more
                                </div>
                            </div>
                        </div>
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-mail kt-font-warning" />
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Messages
                                </div>
                                <div className="kt-notification__item-time">
                                    Inbox and tasks
                                </div>
                            </div>
                        </div>
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-rocket-1 kt-font-danger" />
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Activities
                                </div>
                                <div className="kt-notification__item-time">
                                    Logs and notifications
                                </div>
                            </div>
                        </div>
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-icon">
                                <i className="flaticon2-hourglass kt-font-brand" />
                            </div>
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title kt-font-bold">
                                    My Tasks
                                </div>
                                <div className="kt-notification__item-time">
                                    latest tasks and projects
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="kt-notification__custom">
                            <Link
                                to="/logout"
                                className="btn btn-label-brand btn-sm btn-bold">
                                Cerrar Sesi&oacute;n
                            </Link>
                            <LanguageSelector />
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
