import React from 'react';
import { BaseForm } from '../../../partials/content/BaseForm';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import EdicionGenerica from '../../../partials/content/EdicionGenerica';
import RequestHelper from 'request-helper/dist/request';
import { LocalStorageHelper } from '../../../helpers/LocalStorageHelper';
import Editor from '../../../partials/content/Editor';

export default class NotificacionGeneral extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            entidad: {},
            camposErrores: [],
            tipoMutacion: "crearNotificacionGeneral"
        };

        this.actualizarEntidad = this.actualizarEntidad.bind(this);
        this.guardar = this.guardar.bind(this)
    }

    async actualizarEntidad(notificacion) {
        this.setState({ entidad: notificacion });

        if (!this.leido) {
            this.leido = true;

            //Marco la notificación como leída por el usuario actual
            await RequestHelper.consultaMicroservicio("notificaciones", {
                "query": "mutation leer($id: ID!) { leerNotificacion(id: $id) { resultado errores { codigo mensaje } } }",
                "variables": {
                    "id": notificacion.id,
                }
            }, window.key, LocalStorageHelper.leer("token"));
            //TODO Validar resultado de retorno
        }
    }

    campos() {
        return [
            { nombre: "titulo", tipo: "String" },
            { nombre: "texto", tipo: "String" },
        ];
    }

    async guardar(){
        const query = {
            "query": `mutation guardarNotificacion($notificacionGeneral: InputNotificacionGeneral) {crearNotificacionGeneral(notificacionGeneral: $notificacionGeneral) {resultado errores {mensaje codigo campos} } }`,
            "variables": {
                notificacionGeneral: {
                    titulo: this.state.entidad.titulo,
                    texto: this.state.entidad.texto
                }
            }
        };
        let a =  await RequestHelper.consultaMicroservicio("notificaciones", query, window.key, LocalStorageHelper.leer("token"));
        console.log(a)
        return a
    }

    render() {
        return (
            <EdicionGenerica
                propiedadesItems="  "
                noInicializar
                guardarEspecifico={this.guardar}
                tipoMutacion="crearNotificacionGeneral"
                entidad={this.state.entidad}
                campos={this.campos()}
                actualizarEntidad={this.actualizarEntidad}
                actualizarCamposErrores={x => this.setState({ camposErrores: x })}
                titulo="notificación general"
                microservicio="notificaciones"
                urlListado="/notificaciones/listado"
                nombreEntidad="crearNotificacionGeneral"
                soloVision={false}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label="Título" value={this.state.entidad.titulo || ''} name="titulo" error={this.errorCampo("titulo")} onChange={this.cambioCampo} margin="normal" variant="outlined" InputProps={{ readOnly: false }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Editor
                            editorProps={{
                                name: "texto",
                                value: this.state.entidad.texto || '',
                                onEditorChange: (event) => this.setState({entidad: {...this.state.entidad, texto: event}})
                            }}
                        />
                    </Grid>
                </Grid>
            </EdicionGenerica>
        );
    }
}
