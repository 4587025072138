import React from "react";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import RequestHelper from 'request-helper/dist/request';
import { LocalStorageHelper } from '../../../app/helpers/LocalStorageHelper';

export default class Topbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = { notificaciones: [] };

        this.itemActivado = this.itemActivado.bind(this);
    }

    async componentDidMount() {
        const resultadoNotificaciones = await RequestHelper.consultaMicroservicio("notificaciones", {
            "query": "query getNotificaciones($opcionesPaginado: OpcionesPaginado, $filtro: FiltroNotificacionesUsuario) { notificacionesUsuario(opcionesPaginado: $opcionesPaginado, filtro: $filtro) { resultado errores { codigo mensaje } items { id titulo fecha } } }",
            "variables": {
                "opcionesPaginado": {
                    "itemsPorPagina": 999,
                    "pagina": 1,
                    "orderDir": "desc",
                    "orderBy": "fecha"
                },
                "filtro": {
                    "excluirLeidos": true,
                }
            }
        }, window.key, LocalStorageHelper.leer("token"));
        //TODO Validar resultado de retorno
        const resultado = resultadoNotificaciones?.body?.data?.notificacionesUsuario?.items ?? [];
        this.setState({ notificaciones: resultado });
    }

    itemActivado(notificacion) {
        let notificaciones = this.state.notificaciones;
        const indice = notificaciones.findIndex(x => x.id === notificacion.id);
        if (indice !== -1) {
            notificaciones.splice(indice, 1);
            this.setState({ notificaciones: notificaciones });
        }
    }

    render() {
        return (
            <div className="kt-header__topbar">
                {/*<SearchDropdown useSVG="true" />*/}

                <UserNotifications notificaciones={this.state.notificaciones} itemActivado={this.itemActivado} />

                <UserProfile />
            </div>
        );
    }
}
