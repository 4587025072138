import React from 'react';
import NumberFormat from 'react-number-format';
import Toast from './Toast';

export class BaseForm extends React.Component {
    constructor(props) {
        super(props);
        this.cambioCampo = this.cambioCampo.bind(this);
        this.errorCampo = this.errorCampo.bind(this);
    }

    cambioCampo(event, nombre, nombreEstado = "entidad", despuesDeCambiar) {
        //Controlador de cambios general de todos los formularios
        if (event && typeof event.persist === 'function')
            event.persist();

        //Analizo el valor y campo a modificar según el tipo de control
        let nuevoValor;
        let nombreControl;
        if (event == null) {
            //Si no se tiene ningún valor
            nuevoValor = null;
            nombreControl = nombre;
        }
        else if (Array.isArray(event)) {
            //Para grillas
            nuevoValor = event;
            nombreControl = nombre;
        }
        else if (event instanceof Date) {
            //Para controles de fecha/hora/fecha-hora
            if (!isNaN(event.getTime())) {
                nuevoValor = event.toISOString();
            }
            else {
                nuevoValor = event.getTime();
            }
            nombreControl = nombre;
        }
        else if (event && ["text", "textarea"].indexOf(event.target.type) !== -1) {
            //Para controles simples de texto
            nuevoValor = event.target.value;
            nombreControl = event.target.name;
        }
        else if (event && event.target.type === "password") {
            //Para controles de claves
            nuevoValor = event.target.value;
            nombreControl = event.target.name;
        }
        else if (event && event.target.type === "checkbox") {
            //Para checkbox
            nuevoValor = event.target.checked;
            nombreControl = event.target.name;
        }
        else if (event && event.target.type === "number") {
            //Para controles simples numéricos
            nuevoValor = parseFloat(event.target.value);
            nombreControl = event.target.name;
        }
        else if (event && event.target.type === "color") {
            //Para controles de selección de colores
            nuevoValor = event.target.value;
            nombreControl = event.target.name;
        }
        else if (event && Array.isArray(event.target.value)) {
            //Para controles tipo combobox
            //Cuando se desselecciona un elemento, aparece dos veces en el array de seleccionados
            //Parche: recorro y elimino los duplicados
            nuevoValor = [];
            for (let i = 0; i < event.target.value.length; i++) {
                const nuevoId = event.target.value[i].id;
                const indiceDuplicado = nuevoValor.findIndex(x => x.id === nuevoId);
                if (indiceDuplicado === -1) {
                    nuevoValor.push(event.target.value[i]);
                } else {
                    nuevoValor.splice(indiceDuplicado, 1);
                }
            }
            nombreControl = event.target.name;
        }
        else if (event && !event.target.type) {
            //Otros controles sin un tipo asignado
            nuevoValor = event.target.value;
            nombreControl = event.target.name;
        }
        else {
            throw new Error("No se reconoce el tipo de control");
        }

        //Asigno el valor obtenido al objeto entidad del estado
        if (nombreControl) {
            const nuevaEntidad = this.state[nombreEstado];
            nuevaEntidad[nombreControl] = nuevoValor;
            this.setState({ [nombreEstado]: nuevaEntidad }, despuesDeCambiar);
        }
    }
    errorCampo(campo) {
        return this.state.camposErrores.indexOf(campo) > -1;
    }
    cambioImagen(event, nombreArchivo, nombreImagen, nombreEstado = "entidad", despuesDeCambiar, errorEnCambio) {
        if (typeof event.persist === 'function')
            event.persist();

        const archivo = event.target.files[0];
        if (!archivo) return;

        if(errorEnCambio && archivo.size > 1000000){
            errorEnCambio(archivo.name);
            return;
        }
        
        const reader = new FileReader();
        reader.onloadend = (e) => {
            if (!reader.result) return;

            let entidad = this.state[nombreEstado];
            entidad[nombreArchivo] = archivo.name;
            entidad[nombreImagen] = btoa(reader.result);
            this.setState({ [nombreEstado]: entidad }, despuesDeCambiar);
        };
        reader.readAsBinaryString(archivo);
    }
    eliminarLogo(nombreArchivo, nombreImagen, nombreEstado = "entidad", despuesDeCambiar) {
        let entidad = this.state[nombreEstado];
        entidad[nombreArchivo] = null;
        entidad[nombreImagen] = null;
        this.setState({ [nombreEstado]: entidad }, despuesDeCambiar);
    }
    formateoCampoNumerico(props) {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: parseFloat(values.value.replace(',', '.')),
                        },
                    });
                }}
                defaultValue={0}
                onFocus={event => {
                    event.target.select()
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
            />
        );
    }

    timeoutCombo = null;
    async handleInputChangeAutocomplete(value, callback) {
        if (this.timeoutCombo) clearTimeout(this.timeoutCombo);

        if (!value) return;

        this.timeoutCombo = setTimeout(async () => {
            callback();
        }, 500);
    }
    toastErrorCargaImagen(mostrar, nombreArchivo, cerrarToastHandler) {
        if(!mostrar) return <></>;

        return(
            <Toast
                style={{position: 'fixed', top: '400px', maxWidth: '300px', left: '10px'}}
                cerrarToast={cerrarToastHandler}
                tipo={'error'}
                dontAutoHide
                mensaje={`El límite de tamaño de archivo es 1MB. No se cargó el siguiente archivo: ${nombreArchivo}`}
            />
        )
    }

}
