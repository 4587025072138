import RequestHelper from 'request-helper/dist/request';

export class PermisosHelpers {

    static permisosSoporte = [
        "contenido.emailtemplates",
        "contenido.emailtemplates.ver",
        "contenido.emailtemplates.crear",
        "contenido.emailtemplates.editar",
        "contenido.emailtemplates.eliminar",
        "plugins",
        "plugins.mercadopagobtn",
        "configuracionessoporte",
        "configuracionessoporte.ver",
        "configuracionessoporte.editar",
    ]

    //Recupera el listado de todos los permisos del usuario actual
    static async buscarPermisosUsuario(token) {
        const resultadoPermisos = await RequestHelper.consultaMicroservicio("usuarios", { query: "query { permisosDeUsuario { resultado errores { codigo mensaje } permisos { nombre } } }" }, window.key, token);
        //TODO Validar resultado de retorno
        let permisos = resultadoPermisos.body.data.permisosDeUsuario.permisos;
        const query = {
            "query": `query LlamarValidarToken($token: String) {validarToken(token: $token) {resultado usuario { id usuario nombre apellido email } } }`,
            "variables": {
                "token": token,
            }
        };

        const resultadoValidarToken = await RequestHelper.consultaMicroservicio("usuarios", query, window.key);
        const resultadoUsuario = resultadoValidarToken.body.data.validarToken;
        if(resultadoUsuario.usuario.email != "soporte@simbel.com.ar") {
            for(let permisoSoporte of this.permisosSoporte) {
                let indicePermiso = permisos.findIndex(permiso => permiso.nombre == permisoSoporte);
                if(indicePermiso != -1) permisos.splice(indicePermiso, 1);
            }
        }
        return permisos;
    }

    //Filtra los ítems del menú de acuerdo a los permisos de acceso
    static filtrarMenu(permisos, menuConfig) {
        let items = [];
        this.procesarItemMenu(permisos, items, menuConfig.aside.items);

        return {
            header: menuConfig.header,
            aside: {
                self: menuConfig.aside.self,
                items: items,
            },
        };
    }

    //Procesa recursivamente los ítems de menú para filtrar los menú sin permisos de acceso
    static procesarItemMenu(permisos, listaPadre, listaOriginal) {
        for (let item of listaOriginal) {
            //Verifico que tenga permiso de acceso
            if (item.permiso && permisos.find(x => x.nombre === item.permiso) === undefined) {
                continue;
            }

            let nuevoItem = {
                title: item.title,
                root: item.root,
                bullet: item.bullet,
                translate: item.translate,
                icon: item.icon,
                page: item.page,
            };
            if (item.submenu && item.submenu.length > 0) {
                nuevoItem.submenu = [];
                this.procesarItemMenu(permisos, nuevoItem.submenu, item.submenu);
            }
            listaPadre.push(nuevoItem);
        }
    }
}
