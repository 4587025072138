import React from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import RequestHelper from 'request-helper/dist/request';
import { LocalStorageHelper } from "../../helpers/LocalStorageHelper";
import { Redirect } from "react-router-dom";

export default class Login2 extends React.Component {
    constructor(props) {
        super(props);

        this.enableLoading = this.enableLoading.bind(this);
        this.disableLoading = this.disableLoading.bind(this);
        this.ingresar = this.ingresar.bind(this);

        this.state = {
            loadingButtonStyle: { paddingRight: "2.5rem" },
            usuario: "",
            clave: "",
            mensajeError: "",
            redirigirDashboard: false,
        };
    }

    enableLoading() {
        this.setState({
            loading: true,
            loadingButtonStyle: { paddingRight: "3.5rem" },
        });
    }
    disableLoading() {
        this.setState({
            loading: false,
            loadingButtonStyle: { paddingRight: "2.5rem" },
        });
    }

    async ingresar(event) {
        event.preventDefault();
        this.enableLoading();
        this.setState({ mensajeError: "" });

        const query = {
            "query": `query LlamarLogin($usuario: String, $clave: String) {login(usuario: $usuario, clave: $clave) { resultado errores { mensaje } token usuario { id usuario nombre apellido grupodeusuario { vendedor }} } }`,
            "variables": {
                "usuario": this.state.usuario,
                "clave": this.state.clave,
            }
        };

        const resultadoLogin = await RequestHelper.consultaMicroservicio("usuarios", query, window.key);
        const login = resultadoLogin.body.data.login;
        this.disableLoading();
        if (!login.resultado) {
            this.setState({ mensajeError: login.errores.map(x => x.mensaje).join(", ") });
        } else {
            LocalStorageHelper.guardar("token", login.token);
            LocalStorageHelper.guardar("usuario", login.usuario);
            this.setState({ redirigirDashboard: true });
        }
    }

    render() {
        if (this.state.redirigirDashboard) return (<Redirect to="/" />);

        return (
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h3>Iniciar sesión</h3>
                    </div>

                    <form className="kt-form" onSubmit={this.ingresar}>
                        {this.state.mensajeError ? (
                            <div role="alert" className="alert alert-danger">
                                <div className="alert-text">{this.state.mensajeError}</div>
                            </div>
                        ) : null}

                        <div className="form-group">
                            <TextField
                                label="Usuario"
                                margin="normal"
                                className="kt-width-full"
                                value={this.state.usuario}
                                onChange={e => this.setState({ usuario: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <TextField
                                type="password"
                                margin="normal"
                                label="Clave"
                                className="kt-width-full"
                                value={this.state.clave}
                                onChange={e => this.setState({ clave: e.target.value })}
                            />
                        </div>

                        <div className="kt-login__actions">
                            <button
                                type="submit"
                                disabled={this.state.loading}
                                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                    {
                                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                                    }
                                )}`}
                                style={this.state.loadingButtonStyle}>
                                Ingresar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
