export class LocalStorageHelper {
    static guardar(clave, valor) {
        let guardarValor;
        if (typeof valor === "object")
            guardarValor = JSON.stringify(valor);
        else
            guardarValor = valor;

        try {
            localStorage.setItem(clave, guardarValor);
        } catch (e) {
            console.log(JSON.stringify(e));
            return false;
        }
        return true;
    }

    static leer(clave) {
        try {
            const valor = localStorage.getItem(clave);
            try {
                return JSON.parse(valor);
            } catch (e) {
                return valor;
            }
        } catch (e) {
            console.log(JSON.stringify(e));
            return null;
        }
    }

    static eliminar(clave) {
        try {
            localStorage.removeItem(clave, "");
        } catch (e) {
            console.log(JSON.stringify(e));
            return false;
        }
        return true;
    }
}
