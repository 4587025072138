import { RequestHelper } from "request-helper/dist/request";

export default class PluginsHelper {
    static plugins = null;

    static cargarPlugins(plugins) {
        this.plugins = plugins;
    }

    static pluginPorCodigo(codigo) {
        if (!this.plugins) return null;

        return this.plugins.find(x => x.codigo === codigo);
    }
}