import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

export default class editor extends React.Component{
    constructor(props) {
        super(props)
    }

    render(){
        return (
                <Editor
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'image code',
                        ],
                        toolbar:
                        'undo redo | formatselect | image | code | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help',
                        ...this.props.init
                    }}
                    {...this.props.editorProps}
                />
            )
        }
}
