import React from "react";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import RequestHelper from 'request-helper/dist/request';
import { LocalStorageHelper } from '../../../app/helpers/LocalStorageHelper';

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();
  
    constructor(props) {
        super(props);

        this.state = { notificaciones: [] };

        this.itemActivado = this.itemActivado.bind(this);
    }

    async componentDidMount() {
        const resultadoNotificaciones = await RequestHelper.consultaMicroservicio("notificaciones", {
            "query": "query getNotificaciones($opcionesPaginado: OpcionesPaginado, $filtro: FiltroNotificacionesUsuario) { notificacionesUsuario(opcionesPaginado: $opcionesPaginado, filtro: $filtro) { resultado errores { codigo mensaje } items { id titulo fecha } } }",
            "variables": {
                "opcionesPaginado": {
                    "itemsPorPagina": 999,
                    "pagina": 1,
                    "orderDir": "desc",
                    "orderBy": "fecha"
                },
                "filtro": {
                    "excluirLeidos": true,
                }
            }
        }, window.key, LocalStorageHelper.leer("token"));
        //TODO Validar resultado de retorno
        const resultado = resultadoNotificaciones?.body?.data?.notificacionesUsuario?.items ?? [];
        this.setState({ notificaciones: resultado });
    }

  itemActivado(notificacion) {
      let notificaciones = this.state.notificaciones;
      const indice = notificaciones.findIndex(x => x.id === notificacion.id);
      if (indice !== -1) {
          notificaciones.splice(indice, 1);
          this.setState({ notificaciones: notificaciones });
      }
  }

  mostrarNotificacion(notificacion) {
      this.props.history.push("/notificaciones/form/" + notificacion.id);
      this.setState({ mostrarNotificaciones: false });
      if (this.props.itemActivado) this.props.itemActivado(notificacion);
  }

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      headerLogo,
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes
    } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img alt="SimbelShop." src={headerLogo} />
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}
          
          {/* headerMenuSelfDisplay && (
            <button
              className="kt-header-mobile__toggler"
              id="kt_header_mobile_toggler"
            >
              <span />
            </button>
          ) 
          
          <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button>
          */}

          <UserNotifications notificaciones={this.state.notificaciones} itemActivado={this.itemActivado} />

          <UserProfile />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  })
});

export default connect(mapStateToProps)(HeaderMobile);
