import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { LocalStorageHelper } from "../../helpers/LocalStorageHelper";
import RequestHelper from 'request-helper/dist/request';
import { LayoutSplashScreen } from "../../../_metronic";

export default class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = { deslogueado: false };
    }

    async componentDidMount() {
        const token = LocalStorageHelper.leer("token");
        if (token) {
            const query = {
                "query": `query LlamarLogout($token: String) {logout(token: $token) {resultado} }`,
                "variables": {
                    "token": token,
                }
            };

            await RequestHelper.consultaMicroservicio("usuarios", query, window.key);
            LocalStorageHelper.eliminar("token");
        }
        LocalStorageHelper.eliminar("token");
        LocalStorageHelper.eliminar("usuario");
        this.setState({ deslogueado: true });
    }

    render() {
        return this.state.deslogueado ? <Redirect to="/auth" /> : <LayoutSplashScreen />;
    }
}
