/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import AuthPage from "../pages/auth/AuthPage";
import { LocalStorageHelper } from "../helpers/LocalStorageHelper";

export const Routes = withRouter(({ history }) => {
    const { menuConfig } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
        }),
        shallowEqual
    );

    const logueado = LocalStorageHelper.leer("token") != null;

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>
                {!logueado ? (<AuthPage />) : (<Redirect from="/auth" to="/" />)}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />

                {!logueado ? (<Redirect to="/auth/login" />) : (
                    <Layout>
                        <HomePage />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
});
