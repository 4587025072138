import React from "react";
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "../../partials/content/Portlet";
import { LocalStorageHelper } from "../../helpers/LocalStorageHelper";
import Dropdown from "react-bootstrap/Dropdown";
import RequestHelper from 'request-helper/dist/request';
import Tooltip from '@material-ui/core/Tooltip';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usuario: null,
            paginaDashboard: 1,
            codigo: null,
            ambiente_dashboard: null,
        };

        this.cambiarPaginaDashboard = this.cambiarPaginaDashboard.bind(this);
    }

    async componentDidMount() {
        const token = LocalStorageHelper.leer("token");
        const usuario = LocalStorageHelper.leer("usuario");
        if (usuario != null && usuario.usuario != null)
            this.setState({ usuario: usuario.usuario });

        const resultadoConfiguracionTienda = await RequestHelper.consultaMicroservicio("configuraciones", { query: "query { configuracionTienda { resultado errores { codigo mensaje campos } item { ambiente_dashboard codigo_dashboard } } }" }, window.key, token);
        //TODO Validar resultado de retorno
        const configuracionTienda = resultadoConfiguracionTienda.body.data.configuracionTienda.item;
        this.setState({ ambiente_dashboard: configuracionTienda.ambiente_dashboard, codigo: configuracionTienda.codigo_dashboard });
    }

    cambiarPaginaDashboard(pagina) {
        if (this.paginaDashboard === pagina) return;
        this.setState({ paginaDashboard: pagina });
    }

    styles = {
        dropdownOpciones: {
            marginRight: "0.5rem",
        },
        iconoLinkExterno: {
            fontSize: "13px",
            color: "#687cde"
        }
    }

    render() {
        let servidorDashboard = null;
        if (this.state.ambiente_dashboard === "D") {
            servidorDashboard = "dadev.simbel.com.ar";
        } else if (this.state.ambiente_dashboard === "T") {
            servidorDashboard = "datst.simbel.com.ar";
        } else if (this.state.ambiente_dashboard === "P") {
            servidorDashboard = "daprd.simbel.com.ar";
        }

        return (
            <>
                {servidorDashboard != null && <Portlet fluidHeight={true}>
                    <PortletHeader
                        title={"Dashboard - " + (this.state.paginaDashboard === 1 ? "Visión general" : (this.state.paginaDashboard === 2 ? "Productos" : (this.state.paginaDashboard === 3 ? "Sesiones" : (this.state.paginaDashboard === 4 ? "KPI" : ""))))}
                        toolbar={
                            <PortletHeaderToolbar>
                                <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
                                    <Dropdown.Toggle variant="transparent" className="btn btn-label-success btn-sm btn-bold dropdown-toggle" id="dropdown-toggle-top" style={this.styles.dropdownOpciones}>
                                        Opciones
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
                                        <ul className="kt-nav">
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(1)}>
                                                    <i className={`kt-nav__link-icon flaticon2-pie-chart-1${this.state.paginaDashboard === 1 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 1 ? " kt-font-success" : ""}`}>Visión general</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(2)}>
                                                    <i className={`kt-nav__link-icon flaticon-open-box${this.state.paginaDashboard === 2 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 2 ? " kt-font-success" : ""}`}>Productos</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(3)}>
                                                    <i className={`kt-nav__link-icon flaticon-refresh${this.state.paginaDashboard === 3 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 3 ? " kt-font-success" : ""}`}>Sesiones</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(4)}>
                                                    <i className={`kt-nav__link-icon flaticon-trophy${this.state.paginaDashboard === 4 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 4 ? " kt-font-success" : ""}`}>KPI</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(5)}>
                                                    <i className={`kt-nav__link-icon flaticon-folder-1${this.state.paginaDashboard === 5 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 5 ? " kt-font-success" : ""}`}>Páginas</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(6)}>
                                                    <i className={`kt-nav__link-icon flaticon-web${this.state.paginaDashboard === 6 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 6 ? " kt-font-success" : ""}`}>Banners</span>
                                                </Dropdown.Item>
                                            </li>
                                            <li className="kt-nav__item">
                                                <Dropdown.Item className="kt-nav__link" onClick={() => this.cambiarPaginaDashboard(7)}>
                                                    <i className={`kt-nav__link-icon flaticon2-tag${this.state.paginaDashboard === 7 ? " kt-font-success" : ""}`} />
                                                    <span className={`kt-nav__link-text${this.state.paginaDashboard === 7 ? " kt-font-success" : ""}`}>Campaigns</span>
                                                </Dropdown.Item>
                                            </li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Tooltip title="Abrir en página aparte">
                                    <a href={`https://${servidorDashboard}/Dashboard/FrontServer/?cs=${this.state.codigo}&da=${this.state.paginaDashboard}&ui=${this.state.usuario}`} target={"_blank"}>
                                        <button type="button" className="btn btn-clean btn-md btn-icon btn-icon-md ng-star-inserted">
                                            <i className="fa fa-external-link-alt" style={this.styles.iconoLinkExterno}/>
                                        </button>
                                    </a>
                                </Tooltip>
                            </PortletHeaderToolbar>
                        }
                    />

                    <PortletBody fit={true}>
                    </PortletBody>
                </Portlet>}
            </>
        );
    }
}
