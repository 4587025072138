import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import RequestHelper from 'request-helper/dist/request';
import { LocalStorageHelper } from "./app/helpers/LocalStorageHelper";
import PluginsHelper from "./app/helpers/PluginsHelper";

window.microservicios = [];

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cargado: false
        };

        this.cargar();
    }

    async cargar() {
        if (this.state.cargado) return;

        //TODO: Generalizar el KEY
        const data = await RequestHelper.post(window.super_url, {
            json: {
                query: "query getMicroservicios($key: String!, $keyCliente: String!) { microservicios(key: $key, keyCliente: $keyCliente) { resultado errores microservicios { id nombre codigo protocolo_externo host_externo puerto_externo } } }",
                variables: {
                    key: '264E0CA94753AAAE0D86DAEB9DDF2DD600FA5095E91C15B3DDD8F82D9E683FE4182766D0F6065282781E9946869CEE992A20AD4FBB6DF1B4634B80DBBA823F6D',
                    keyCliente: window.key,
                }
            }
        });

        if (data.body.data && data.body.data.microservicios && data.body.data.microservicios.microservicios) {
            const microservicios = data.body.data.microservicios.microservicios;
            if (Array.isArray(microservicios)) {
                for (let ms of microservicios) {
                    window.microservicios.push({
                        id: parseInt(ms.id),
                        nombre: ms.nombre,
                        codigo: ms.codigo,
                        host: ms.host_externo
                    });
                }

                RequestHelper.configurarMicroservicios(window.microservicios);

                LocalStorageHelper.eliminar("usuario");
                const token = LocalStorageHelper.leer("token");
                if (!token) {
                    LocalStorageHelper.eliminar("token");
                } else {
                    const query = {
                        "query": `query LlamarValidarToken($token: String) {validarToken(token: $token) {resultado usuario { id usuario nombre apellido grupodeusuario { vendedor } } } }`,
                        "variables": {
                            "token": token,
                        }
                    };

                    const resultadoValidarToken = await RequestHelper.consultaMicroservicio("usuarios", query, window.key);
                    const validarToken = resultadoValidarToken.body.data.validarToken;
                    if (!validarToken.resultado) {
                        LocalStorageHelper.eliminar("token");
                    }
                    LocalStorageHelper.guardar("usuario", validarToken.usuario);
                }
            }
        }

        // Cargo los plugins
        const dataPlugins = await RequestHelper.post(window.super_url, {
            json: {
                query: "query getPlugins($key: String!){ plugins(key: $key) { resultado errores plugins { id nombre codigo descripcion host } } }",
                variables: {
                    key: '264E0CA94753AAAE0D86DAEB9DDF2DD600FA5095E91C15B3DDD8F82D9E683FE4182766D0F6065282781E9946869CEE992A20AD4FBB6DF1B4634B80DBBA823F6D'
                }
            }
        });

        if (dataPlugins?.body?.data?.plugins?.plugins) {
            PluginsHelper.cargarPlugins(dataPlugins.body.data.plugins.plugins);
        }

        this.setState({
            cargado: true
        });
    };

    render() {
        return (this.state.cargado &&
            /* Provide Redux store */
            <Provider store={this.props.store}>
                {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
                <PersistGate persistor={this.props.persistor} loading={<LayoutSplashScreen />}>
                    {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                    <React.Suspense fallback={<LayoutSplashScreen />}>
                        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                        <BrowserRouter basename={this.props.basename}>
                            {/* Provide Metronic theme overrides. */}
                            <ThemeProvider>
                                {/* Provide `react-intl` context synchronized with Redux state.  */}
                                <I18nProvider>
                                    {/* Render routes with provided `Layout`. */}
                                    <Routes />
                                </I18nProvider>
                            </ThemeProvider>
                        </BrowserRouter>
                    </React.Suspense>
                </PersistGate>
            </Provider>
        );
    }
}
