import React from "react";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { withRouter } from 'react-router-dom';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

class UserNotifications extends React.Component {
    constructor(props) {
        super(props);;

        this.state = {
            tab: "Notificaciones",
            mostrarNotificaciones: false,
        };
    }

    fechaHoraVisible(fecha) {
        if (!fecha) return "";

        const date = new Date(fecha);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " " + ('0' + (date.getHours())).slice(-2) + ":" + ('0' + (date.getMinutes())).slice(-2);
    }

    getHeaderTopBarCssClassList = () => {
        let result = "kt-header__topbar-icon ";
        if (this.props.notificaciones?.length > 0) {
            result += "kt-pulse kt-pulse--brand ";
        }

        return result;
    };

    mostrarNotificacion(notificacion) {
        this.props.history.push("/notificaciones/form/" + notificacion.id);
        this.setState({ mostrarNotificaciones: false });
        if (this.props.itemActivado) this.props.itemActivado(notificacion);
    }

    render() {
        return (
            <Dropdown className="kt-header__topbar-item" drop="down" alignRight show={this.state.mostrarNotificaciones} onToggle={() => this.setState({ mostrarNotificaciones: !this.state.mostrarNotificaciones })}>
                <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-notifications">
                    <span className={this.getHeaderTopBarCssClassList()}>
                        <i className={"flaticon2-notification" + (this.props.notificaciones.length > 0 ? " kt-font-brand" : "")} />
                        {this.props.notificaciones && this.props.notificaciones.length > 0 && <span className="kt-pulse__ring" />}
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
                    <form>
                        <div className="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b" style={{ backgroundImage: "url(/media/misc/bg-1.jpg)" }}>
                            <h3 className="kt-head__title">
                                Notificaciones&nbsp;
                                <span className="btn btn-success btn-sm btn-bold btn-font-md">{this.props.notificaciones.length}</span>
                            </h3>

                            <Tab.Container defaultActiveKey={this.state.tab} className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x">
                                <Nav className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x" onSelect={x => this.setState({ tab: x })}>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link eventKey="Notificaciones" className="nav-link show">
                                            Notificaciones
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="Notificaciones">
                                        <PerfectScrollbar options={perfectScrollbarOptions} style={{ maxHeight: "100vh", position: "relative" }}>
                                            <div className="kt-notification kt-margin-t-10 kt-margin-b-10" style={{ maxHeight: "40vh", position: "relative" }}>
                                                <div
                                                    className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                                                    data-scroll="true"
                                                    data-height="300"
                                                    data-mobile-height="200">

                                                    {this.props.notificaciones.map(x => (
                                                        <a key={x.id} className="kt-notification__item" onClick={() => this.mostrarNotificacion(x)}>
                                                            <div className="kt-notification__item-icon">
                                                                <i className="flaticon2-notification kt-font-success" />
                                                            </div>
                                                            <div className="kt-notification__item-details">
                                                                <div className="kt-notification__item-title">{x.titulo}</div>
                                                                <div className="kt-notification__item-time">{this.fechaHoraVisible(x.fecha)}</div>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default withRouter(UserNotifications);
